<template>
  <el-upload
    action=''
    :auto-upload='false'
    :on-preview='openPlayer'
    :on-change='handleFileUpload'
    :on-remove='handleFileRemove'
    :on-exceed='handleFileExceed'
    :multiple='false'
    :file-list='posterFileList'
    accept='video/*'>
    <div class='w-full py-2 border rounded-md hover:bg-gray-50 hover:shadow-md'>
      <i class='text-2xl text-gray-700' :class='uploadBoxIcon'></i>
      <div class='text-sm mb-1 uppercase'> {{uploadBoxText}} </div>
    </div>
  </el-upload>
</template>

<script>
import uploadApi from '@/api/v1/video-uploads'
import * as tus from 'tus-js-client'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import axios from 'axios'


export default {
  name: 'VideoUpload',
  props: ['poster'],
  data () {
    return {
      video: null,
      currentUploadLink: '',
      progress: 0,
      uploading: false,
      requestedUploadUrl: false
    }
  },
  watch: {
    video: {
      handler: function (newVal, oldVal) {
        if ((newVal && newVal !== oldVal) || !newVal) {
          this.progress = 0
          this.requestedUploadUrl = false
        }
      }
    },
    progress: {
      handler: function (newVal) {
        if (newVal == 100.0) {
          this.uploading = false
        }
      },
    },
  },
  computed: {
    ...mapGetters('events', [
      'showingEventId'
    ]),
    posterFileList () {
      return this.poster.vimeoVideoId ? [{name: this.poster.videoOriginalFilename, url: `https://player.vimeo.com/video/${this.poster.vimeoVideoId}?h=0708cf4566`}] : []
    },
    removeParams () {
      return {
        event_id: this.showingEventId,
        video_upload_id: null,
        vimeo_video_id: ''
      }
    },
    uploadBoxIcon () {
      if (this.progress === 0 && !this.uploading) {
        return 'el-icon-upload'
      } else if (this.progress < 0 && this.requestedUploadUrl) {
        return 'el-icon-loading'
      } else if (this.progress < 100 && this.uploading) {
        return 'el-icon-loading'
      } else if (this.progress == 100 && !this.uploading) {
        return 'el-icon-circle-check'
      } else {
        return ''
      }
    },
    uploadBoxText () {
      if (this.progress === 0 && !this.uploading) {
        return 'click to upload video'
      } else if (this.progress < 0 && this.requestedUploadUrl) {
        return `${this.progress}%`
      } else if (this.progress < 100 && this.uploading) {
        return `${this.progress}%`
      } else if (this.progress == 100 && !this.uploading) {
        return 'Done'
      } else {
        return ''
      }
    },
  },
  methods: {
    ...mapActions('posters', [
      'updateMyPoster',
    ]),
    ...mapMutations('posters', [
      'updateMyPosters',
    ]),
    checkUpload () {
      if (this.currentUploadLink) {
        return axios.head(
          this.currentUploadLink,
          {
            headers: {
              'Tus-Resumable': '1.0.0',
              'Accept': 'application/vnd.vimeo.*+json;version=3.4'
            }
          }
        ).then(resp => {
          let currentByte = parseInt(resp.headers['upload-offset'])
          let totalByte = parseInt(resp.headers['upload-length'])
          this.progress = currentByte * 100.0 / totalByte
        })
      }
    },
    openPlayer (file) {
      window.open(file.url, '_blank').focus()
    },
    startCheckUpload () {
      if (this.uploading && this.progress < 100.0) {
        this.checkUpload().then(() => setTimeout(this.startCheckUpload, 5000))
      }
    },
    startUpload (file) {
      if (file) {
        this.requestedUploadUrl = true
        uploadApi.postVideoUpload({
          poster_id: this.poster.id,
          event_id: this.showingEventId,
          original_filename: file.name,
          size_bytes: file.size,
          name: `${process.env.NODE_ENV}-${this.showingEventId}-${this.poster.posterNo}`,
          description: `${process.env.NODE_ENV}-${this.showingEventId}-${this.poster.title}-${file.name}`,
        }).then(resp => {
          this.currentUploadLink = resp.vimeo_upload_link
          let upload = new tus.Upload(file.raw, {
            uploadUrl: resp.vimeo_upload_link,
            onError: (error) => {
              console.error("Failed because: " + error)
              this.$message.warning("Failed because: " + error)
            },
            onSuccess: () => {
              this.updateMyPosters(Object.assign(this.poster, {vimeoVideoId: resp.vimeo_video_id, videoOriginalFilename: file.name}))
              console.log("Download %s from %s", upload.file.name, upload.url)
              this.$alert('Video upload completed. Allow video to transcode before playing.', this.showingEventName, {
                type: 'success',
                confirmButtonText: 'OK' })
            }
          })
          upload.start()
          this.uploading = true
          this.startCheckUpload()
        }).catch(err => {
          if (err.response) {
            console.error(err.response.data)
          }
        })
      }
    },
    handleFileUpload (file) {
      this.video = file
      this.startUpload(file)
    },
    handleFileRemove () {
      this.video = null
      this.updateMyPoster({posterId: this.poster.id, posterParams: this.removeParams}).then(() => {
        this.$alert('Poster remove completed', this.showingEventName, {
          type: 'success',
          confirmButtonText: 'OK' })
      })
    },
    handleFileExceed () {
      this.$message.warning('최대 1개의 Poster를 업로드 할 수 있습니다.')
    },
  },
}
</script>

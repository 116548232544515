<template>
  <div class='flex flex-col lg:flex-row min-h-screen'>
    <div class='w-full lg:w-1/3 px-4 lg:px-16 py-4 text-gray-50 flex flex-col items-center justify-center relative'
         :style='`background-color: ${eventMainThemeColor}`'>
      <div>
        <h1 class='text-3xl font-medium mb-4'>Registration</h1>
      </div>
      <div class='absolute bottom-2 text-center'>
        <button
          class='text-xs text-gray-50 hover:shadow-md hover:underline'
          @click='logoutApp'>Logout
        </button>
      </div>
    </div>
    <poster-upload-component
      class='w-full lg:w-2/3 p-4 lg:px-16 text-gray-600 flex flex-col justify-center'
      style="min-height: calc(100vh - 5rem);"/>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import PosterUploadComponent    from '@/components/posters/PosterUpload.vue'

export default {
  name: 'PosterUpload',
  components: {
    PosterUploadComponent
  },
  computed: {
    ...mapGetters('events',[
      'eventMainThemeColor',
    ])
  },
  methods: {
    ...mapActions('posters', [
      'getMyPosters'
    ]),
    ...mapActions('users', [
      'logout'
    ]),
    logoutApp () {
      this.logout().then(() => {
        this.$router.push({name: 'Login'})
      })
    },
    getMyPostersData () {
      this.getMyPosters().catch(() => {
        this.$alert('Cannot find your poster info. Please contact us.\nsupport@webconcert.co.kr', window.location.host.replace('www.','').split('.')[0], {
          type: 'warning',
          confirmButtonText: 'OK'
        }).then(() => {
          this.$router.push({name: 'Login'})
        })
      })
    }
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
    this.getMyPostersData()
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
  },
}
</script>

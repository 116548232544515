<template>
  <div>
    <div class='mb-4 font-medium'>{{poster.title}}</div>
    <div class='text-sm mb-4'>{{posterAuthorsListString}}</div>
    <pdf-upload v-if='pdfType' :poster='poster' class='w-2/3 lg:w-1/3' />
    <video-upload v-else-if='videoType' :poster='poster' class='w-2/3 lg:w-1/3' />
  </div>
</template>

<script>
import PdfUpload                  from '@/components/posters/PdfUpload.vue'
import VideoUpload                from '@/components/posters/VideoUpload.vue'

export default {
  name: 'PosterUploadRow',
  props: ['poster'],
  components: {
    PdfUpload,
    VideoUpload,
  },
  computed: {
    posterAuthorsListString () {
      return (this.poster.authors) ? this.poster.authors.list.join(', ') : ''
    },
    pdfType () {
      return this.poster.posterType === 'pdf'
    },
    videoType () {
      return this.poster.posterType === 'video'
    },
  },
  methods: {
  }
}
</script>

<style lang="scss">
  .el-upload {
    @apply w-full;
  }
</style>

<template>
  <el-upload
    action=''
    :auto-upload='false'
    :on-change='handleFileUpload'
    :on-remove='handleFileRemove'
    :on-exceed='handleFileExceed'
    :multiple='false'
    :file-list='posterFileList'
    accept='.pdf'>
    <div class='w-full py-2 border rounded-md hover:bg-gray-50 hover:shadow-md'>
      <i class='el-icon-upload text-2xl'></i>
      <div class='text-sm mb-1 uppercase'>click to upload pdf</div>
    </div>
  </el-upload>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import isEmpty                    from 'lodash/isEmpty'
import cloneDeep                  from 'lodash/cloneDeep'

export default {
  name: 'PdfUpload',
  props: ['poster'],
  data () {
    return {
      userPoster: {},
    }
  },
  watch: {
    'poster.posterFile': {
      handler(newVal) {
        if (!isEmpty(newVal)) {
          this.userPoster = cloneDeep(newVal)
          fetch(this.userPoster.url).then(r => r.blob()).then(blob => this.userPoster.file = blob)
          this.userPoster.name = decodeURI(this.userPoster.name)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('events', [
      'showingEventId',
      'showingEventName',
    ]),
    formData () {
      let data = new FormData()
      if (isEmpty(this.userPoster)) {
        data.append('poster[poster_file]', '')
      } else {
        data.append('poster[poster_file]', this.userPoster.file, this.poster.posterNo)
      }
      data.append('poster[event_id]', this.showingEventId)
      return data
    },
    posterFileList () {
      return !isEmpty(this.poster.posterFile) ? [this.userPoster] : []
    },
    posterAuthorsListString () {
      return (this.poster.authors) ? this.poster.authors.list.join(', ') : ''
    },
  },
  methods: {
    ...mapActions('posters', [
      'updateMyPoster',
    ]),
    handleFileUpload (file) {
      this.userPoster = { file: file.raw, name: file.name }
      this.updateMyPoster({posterId: this.poster.id, posterParams: this.formData}).then(() => {
        this.$alert('Poster upload completed', this.showingEventName, {
          type: 'success',
          confirmButtonText: 'OK' })
      })
    },
    handleFileRemove () {
      this.userPoster = {}
      this.updateMyPoster({posterId: this.poster.id, posterParams: this.formData}).then(() => {
        this.$alert('Poster remove completed', this.showingEventName, {
          type: 'success',
          confirmButtonText: 'OK' })
      })
    },
    handleFileExceed () {
      this.$message.warning('최대 1개의 Poster를 업로드 할 수 있습니다.')
    },
  }
}
</script>

<style lang='scss'>
  .el-upload {
    @apply w-full;
  }
</style>

<template>
    <div class='w-full lg:w-2/3 px-4 lg:px-16 text-gray-600 flex flex-col justify-center'
         style="min-height: calc(100vh - 5rem);">
      <h1 class='text-2xl font-semibold mb-12'>Poster Upload</h1>
      <div v-for='poster in myPosters'
           :key='`poster-${poster.id}`'>
        <poster-upload-row :poster='poster' class='mb-20' />
      </div>
      <button
        class='uppercase w-2/3 lg:w-1/3 p-4 rounded-md text-gray-50 hover:shadow-md mb-4'
        :class='emptyFieldUploadDoneButtonClass'
        :style='`background-color: ${eventMainThemeColor}`'
        :disabled='!hasMyPostersFile'
        @click='goToPosterUploadCompleted'>
        Upload
      </button>
      <ul class='list-disc list-inside text-red-600 text-xs'>
        <li v-if='!hasMyPostersFile'>Please upload file</li>
      </ul>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PosterUploadRow from '@/components/posters/PosterUploadRow.vue'

export default {
  name: 'PosterUpload',
  components: {
    PosterUploadRow
  },
  computed: {
    ...mapState('posters', [
      'myPosters'
    ]),
    ...mapGetters('posters', [
      'hasMyPostersFile'
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'eventMainThemeColor',
    ]),
    emptyFieldUploadDoneButtonClass () {
      return (!this.hasMyPostersFile) ? 'opacity-25 cursor-default' : ''
    },
  },
  methods: {
    goToPosterUploadCompleted () {
      this.$router.push({name: 'PosterUploadCompleted'})
    }
  },
}
</script>
